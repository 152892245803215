@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,500&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap");


body {
  background: linear-gradient(
    90deg,
    rgba(214, 204, 240, 1) 0%,
    rgb(239, 239, 239) 50%,
    rgba(214, 204, 240, 1) 100%
  ) !important;
  user-select: none !important;
}
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
a{
  text-decoration: none !important;
}
body::-webkit-scrollbar-track {
  background: #ffffff !important;
}
body::-webkit-scrollbar-thumb {
  background: #752bc0 !important;
  border-radius: 16px;
}
body::-webkit-scrollbar {
  width: 5px;
}
//navbar
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #2c3a4b;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #2c3a4b;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.heading{
  font-family: "Montserrat";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-align: center;
    color: #545d69;
    text-transform: capitalize;
    margin: 40px 0px !important;
}
.hero-cards{
  border: none !important;
  background: transparent !important;
  // background: rgba(255, 255, 255, 0.413) !important;
  h2{
    color: #394452 !important; 
    font-family: "Ubuntu" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 130% !important;
  }
  p{
    color: #394452;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .005em;
    line-height: 170%;
    text-align: justify;
    text-transform: capitalize;
  }
}
.arrow{
  background: linear-gradient(90deg,#fff 4%,hsla(0,0%,100%,0) 93.05%);
  height: 40px;
  cursor: pointer;
  padding: 16px 20px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #2c3a4b;
  font-size: 30px !important;
}
.form-btn {
  width: 150px;
  height: 40px;
  border: none;
  
  background: linear-gradient(90deg,#fff 4%,hsla(0,0%,100%,0) 93.05%);

}

/*******************************************************Footer***************************************/
/*******************************************************Section***************************************/
.footer-padding {
  padding-top: 100px;
}
.footer {
  span {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #545d69;
  }
  h2 {
    margin: 20px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #545d69;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #545d69;
  }
  h1 {
    margin-top: 30px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #545d69;
  }
  .icon-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .icon {
      width: 50px;
      height: 50px;
      padding: 15px;
      color: red;
      border-radius: 6px;
      background-color: white;
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      text-transform: capitalize;
      color: #545d69;
    }
    a {
      text-decoration: none !important;
      color: #5b8def;
    }
  }
}

.form-control {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 170%;
  padding: 15px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  color: #2c3a4b;
  box-shadow: none !important;
  outline: none !important;
}
.footer-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  width: 143px;
  height: 52px;
  border: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #394452;
  margin-top: 30px;
  background: linear-gradient(90deg, #c8c7c7 4%, rgba(255, 255, 255, 0) 93.05%);
}
.footer-bottom {
  margin-top: 50px;
  margin-bottom: 20px;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #545d69 !important;
  }
  a {
    margin: 10px;
    color: #545d69 !important;

  }
}
